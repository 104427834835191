var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.liberaIconeChat)?_c('div',{staticClass:"icone-chat",class:{ digitando: _vm.carregandoResposta },on:{"click":function($event){return _vm.alternarChat(_vm.chatAberto)}}},[_c('img',{ref:"iconeRobo",staticClass:"icone-robo",attrs:{"src":_vm.logoIAPadraoBranco,"alt":"icone assistente outbox"}})]):_vm._e(),_c('transition',{attrs:{"name":"chat"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[(_vm.chatAberto)?_c('div',{ref:"chatWindow",staticClass:"janela-chat"},[_c('div',{staticClass:"titulo-chat"},[_c('div',{staticClass:"titulo-box"},[_c('span',{staticClass:"fundo-logo-ia"},_vm._l((_vm.imagens),function(imagem){return _c('img',{key:imagem.key,staticClass:"logo-ia",class:{
                ativo: _vm.imagemAtual === imagem.key,
                'robo-gif': !imagem.key.includes('Padrao'),
              },attrs:{"src":imagem.src}})}),0),_c('span',{ref:"chatWindowDrag",staticClass:"titulo",on:{"mousedown":_vm.startDrag,"mousemove":_vm.whileDragging,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}},[_c('span',{staticClass:"texto-nome"},[_vm._v(" Singú ")]),_c('span',{staticClass:"texto-desc"},[_vm._v(" I.A. → Assistente Pessoal ")])])]),_c('span',{staticClass:"icones-box"},[_c('img',{ref:"iconeAjuda",staticClass:"icone-ajuda",attrs:{"src":_vm.iconeCarrossel,"alt":"icone ajuda assistente outbox"},on:{"click":function($event){_vm.abreCarrossel = !_vm.abreCarrossel}}}),_c('span',{staticClass:"material-symbols-outlined icone-fechar",on:{"click":function($event){_vm.chatAberto = false}}},[_vm._v(" close ")])])]),_c('div',{ref:"chatContainer",staticClass:"corpo-chat",on:{"scroll":_vm.monitoraScrollUsuario,"wheel":_vm.monitoraScrollUsuario}},[_vm._l((_vm.historicoChatSuperbox),function(mensagem,indice){return _c('div',{key:indice,staticStyle:{"display":"flex"},style:({
            'justify-content':
              mensagem.enviado === true
                ? 'flex-end'
                : mensagem.enviado === false
                ? 'flex-start'
                : 'center',
          })},[(mensagem.divisor)?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[(mensagem.divisor)?_c('div',{staticClass:"mensagem-divisor",domProps:{"innerHTML":_vm._s(mensagem.texto)}}):_vm._e()]):(
              (mensagem.tipo == 'texto' && mensagem.texto != '') ||
              (mensagem.tipo === 'imagem' && mensagem.imagem != '')
            )?_c('div',{class:{
              'mensagem-chat': true,
              enviado: mensagem.enviado,
              recebido: !mensagem.enviado,
            }},[(mensagem.tipo == 'texto')?_c('div',{domProps:{"innerHTML":_vm._s(mensagem.texto)},on:{"click":function($event){return _vm.handleClick($event)}}}):_vm._e(),(mensagem.tipo === 'imagem' && mensagem.imagem != '')?_c('div',[_c('img',{staticClass:"chat-imagem",attrs:{"src":'data:image/png;base64,' + mensagem.imagem,"alt":"Imagem do chat"},on:{"click":function($event){return _vm.abrirPopup('data:image/png;base64,' + mensagem.imagem)}}})]):_vm._e(),_c('div',{staticClass:"hora-msg"},[_vm._v(" "+_vm._s(mensagem.hora)+" ")])]):_vm._e()])}),(!_vm.termosUsoAssistente)?[_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"texto-termo"},[_vm._v(" Usuário concorda com os "),_c('a',{attrs:{"href":_vm.linkTermos,"target":"_blank"}},[_c('strong',[_vm._v(" Termos de Uso ")])]),_vm._v(" do nosso serviço de chat, incluindo o uso de seus dados para melhorar a experiência de atendimento e a conformidade com nossas políticas de privacidade. ")]),_c('div',{staticClass:"check-termo"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkTermosUsoAssistente),expression:"checkTermosUsoAssistente"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.checkTermosUsoAssistente)?_vm._i(_vm.checkTermosUsoAssistente,null)>-1:(_vm.checkTermosUsoAssistente)},on:{"change":function($event){var $$a=_vm.checkTermosUsoAssistente,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkTermosUsoAssistente=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkTermosUsoAssistente=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkTermosUsoAssistente=$$c}}}}),_c('span',{staticClass:"texto-check"},[_vm._v("Estou de acordo")])]),_c('div',{staticClass:"botoes-box"},[_c('button',{staticClass:"botao-termos declinar",on:{"click":function($event){_vm.chatAberto = false}}},[_vm._v(" Declinar ")]),_c('button',{staticClass:"botao-termos prosseguir",class:{
                  disabled: !_vm.checkTermosUsoAssistente,
                },on:{"click":function($event){return _vm.prosseguirChat()}}},[_vm._v(" Prosseguir ")])])])]:_vm._e()],2),[_c('div',{staticClass:"chat-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.entradaChat),expression:"entradaChat"}],staticClass:"entrada-chat",attrs:{"placeholder":"Mensagem...","maxlength":"1000"},domProps:{"value":(_vm.entradaChat)},on:{"keydown":_vm.verificarEnvio,"input":[function($event){if($event.target.composing)return;_vm.entradaChat=$event.target.value},function($event){return _vm.checkTransicao(_vm.entradaChat)}]}}),_c('div',{staticClass:"contador"},[_vm._v(_vm._s(_vm.entradaChat.length)+"/1000")]),(!_vm.carregandoResposta && !_vm.carregandoRequisicao)?_c('span',{staticClass:"material-symbols-outlined botao-enviar",class:{
              disabled: _vm.entradaChat == '' || _vm.carregandoResposta || !_vm.termosUsoAssistente,
            },on:{"click":function($event){return _vm.enviarChat()}}},[_vm._v(" send ")]):(_vm.carregandoResposta && !_vm.carregandoRequisicao)?_c('span',{staticClass:"material-symbols-outlined botao-enviar",on:{"click":function($event){return _vm.stopSimulacao()}}},[_vm._v(" stop_circle ")]):_vm._e(),(_vm.carregandoRequisicao)?_c('md-progress-spinner',{staticClass:"botao-enviar loading",attrs:{"md-diameter":25,"md-stroke":2,"md-mode":"indeterminate"}}):_vm._e(),_c('div',{staticClass:"chat-aviso"},[_vm._v(" Singú está sujeito a falhas. Sempre confira as informações. ")])],1)]],2):_vm._e()]),(_vm.imagemPopup)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fecharPopup.apply(null, arguments)}}},[_c('div',{ref:"popupContent",staticClass:"popup-content"},[_c('img',{ref:"imagemExpandida",staticClass:"imagem-expandida",style:({
          transform: `scale(${_vm.zoomLevel})`,
        }),attrs:{"src":_vm.imagemPopup,"alt":"Imagem expandida"},on:{"wheel":_vm.handleZoomWheel}})]),_c('div',{staticClass:"zoom-controls"},[_c('span',{staticClass:"material-symbols-outlined botoes-ajuste",on:{"click":_vm.fecharPopup}},[_vm._v(" close ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste add",class:{ disabled: _vm.zoomLevel == 3 },on:{"click":_vm.zoomIn}},[_vm._v(" zoom_in ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste remove",class:{ disabled: _vm.zoomLevel == 1 },on:{"click":_vm.zoomOut}},[_vm._v(" zoom_out ")]),_c('span',{staticClass:"material-symbols-outlined botoes-ajuste restore",on:{"click":_vm.restauraZoom}},[_vm._v(" crop_free ")])])]):_vm._e(),(_vm.abreCarrossel)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fechaCarrossel()}}},[_c('div',{staticClass:"card-carrossel"},[_c('CarrosselSingu',{on:{"fechaCarrossel":_vm.fechaCarrossel}})],1)]):_vm._e(),(_vm.abreListaPerguntas)?_c('div',{staticClass:"popup-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.fechaListaPerguntas()}}},[_c('ListaPerguntasSugeridas',{attrs:{"listaPerguntas":_vm.listaPerguntas},on:{"enviaPerguntaSelecionada":_vm.enviaPerguntaSelecionada}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }