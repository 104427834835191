<template>
  <div class="container-box">
    <div class="box-selecao">
      <div class="input-select">
        <md-field>
          <label for="tipoAssistenteSelecionado"
            >Selecione o assistente que deseja utilizar</label
          >
          <md-select
            v-model="tipoAssistenteSelecionado"
            name="tipoAssistenteSelecionado"
            id="tipoAssistenteSelecionado"
          >
            <md-option
              v-for="opcao in opcoesChat"
              :key="opcao.valor"
              :value="opcao.valor"
            >
              {{ opcao.nome }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="box-saida">
      <SaidaChatOutbox
        ref="saidaChatOutbox"
        :entradaChat="entradaChat"
        :historicoChat="historicoChat"
        :thread_id="thread_id"
        :imagemAtual="imagemAtual"
        :tipoAssistenteSelecionado="tipoAssistenteSelecionado"
        :carregandoResposta="carregandoResposta"
        :carregandoRequisicao="carregandoRequisicao"
        :ativarHistorico="ativarHistorico"
        @enviarChat="enviarChat"
        @checkTransicao="checkTransicao"
        @stopSimulacao="stopSimulacao"
        @interacaoUsuario="handleInteracaoUsuario"
        @atualizaHistorico="atualizaHistorico"
      />
      <HistoricoChat
        v-if="tipoAssistenteSelecionado == 'singuOutbox'"
        @enviaHistoricoSelecionado="enviaHistoricoSelecionado"
        @novoChat="novoChat"
      />

      <SaidaGestorConsorcio
        v-if="tipoAssistenteSelecionado == 'singuConsorcio'"
        ref="saidaGestorConsorcio"
        :entradaChat="entradaChat"
        :historicoChat="historicoChat"
        :thread_id="thread_id"
        :imagemAtual="imagemAtual"
        @enviarMensagemSingu="enviarMensagemSingu"
        @enviarImagemSingu="enviarImagemSingu"
        @stopSimulacao="stopSimulacao"
      />
    </div>
    <template v-if="!termosUsoAssistente">
      <div class="overlay">
        <div class="texto-termo">
          Usuário concorda com os
          <a :href="linkTermos" target="_blank">
            <strong> Termos de Uso </strong>
          </a>
          do nosso serviço de chat, incluindo o uso de seus dados para melhorar a
          experiência de atendimento e a conformidade com nossas políticas de privacidade.
        </div>
        <div class="check-termo">
          <input type="checkbox" v-model="checkTermosUsoAssistente" class="checkbox" />
          <span class="texto-check">Estou de acordo</span>
        </div>
        <div class="botoes-box">
          <button
            class="botao-termos prosseguir"
            :class="{
              disabled: !checkTermosUsoAssistente,
            }"
            @click="prosseguirChat()"
          >
            Prosseguir
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { chatOutboxHistoricoStore } from "@/stores/chat-Outbox-historico.js";
import { chatOutboxFormatacoesStore } from "@/stores/chat-Outbox-formatacoes.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import SaidaChatOutbox from "@/components/AssistenteSingu/SaidaChatOutbox.vue";
import SaidaGestorConsorcio from "@/components/AssistenteSingu/SaidaGestorConsorcio.vue";
import HistoricoChat from "@/components/AssistenteSingu/HistoricoChat.vue";

export default {
  name: "PaginaAssistenteSingu",

  components: { SaidaChatOutbox, SaidaGestorConsorcio, HistoricoChat },

  data() {
    return {
      tipoAssistenteSelecionado: "singuOutbox",
      entradaChat: "",
      historicoChat: [],
      thread_id: "",

      imagemAtual: "logoIAPadrao",
      timerId: null,

      dataFormatada: "",
      horaFormatada: "",

      carregandoResposta: false,
      carregandoRequisicao: false,
      contextoAtual: {
        codigo_agrupador: "",
        rota: "/singu/chat",
        nome: "",
        descricao: "",
      },

      opcoesChat: [
        {
          valor: "singuOutbox",
          nome: "Singú - Assistente Outbox",
        },
        // {
        //   valor: "singuSuperbox",
        //   nome: "Singú - Assistente Superbox",
        // },
        {
          valor: "singuConsorcio",
          nome: "Singú - Gestor de Grupos de Consórcio",
        },
      ],

      linkTermos: "/docs/Termos de serviço - Assistente Outbox.pdf",

      textoCompletoSimulado: "",
      idIntervaloSimulacao: 0,
      caminhoImagemTemporario: "",

      checkTermosUsoAssistente: false,

      listaAvisosInvalido: [
        "Desculpe, não consegui entender sua pergunta. Poderia reformulá-la?",
        "Ops, não consegui captar o que você quis dizer. Poderia explicar de outra forma?",
        "Parece que não entendi sua pergunta. Você poderia reformular, por favor?",
        "Hmm, não consegui processar sua pergunta corretamente. Tente me dizer de outra maneira.",
        "Desculpe, não entendi o que você quis dizer. Poderia ser um pouco mais claro?",
        "Não consegui compreender sua pergunta. Você poderia ser mais específico?",
        "Acho que não captei sua questão. Você pode tentar novamente, por favor?",
        "Poxa, parece que não entendi. Você pode reescrever sua pergunta?",
        "Minhas desculpas, mas não consegui entender sua pergunta. Você poderia tentar de novo?",
        "Uh oh, acho que me confundi. Pode reformular sua pergunta?",
        "Não entendi bem isso. Você se importa de explicar de outra forma?",
      ],

      listaAvisosContexto: [
        "Oops... Ainda estou aprendendo! Hoje só posso te responder se você estiver em alguma das páginas de consultas Superbox.",
        "Ops, ainda estou em treinamento! Por enquanto, só consigo responder perguntas nas páginas de consultas Superbox.",
        "Desculpe, ainda estou aprendendo. Neste momento, só posso ajudar nas páginas de consultas Superbox.",
        "Parece que estou um pouco limitado agora. Só posso responder dentro das páginas de consultas Superbox.",
        "Estou em fase de aprendizado e, por ora, só posso te ajudar nas páginas de consultas Superbox.",
        "Por enquanto, estou equipado para responder apenas nas páginas de consultas Superbox.",
        "Ainda estou em fase de treinamento. Me encontre nas páginas de consultas Superbox para te ajudar!",
        "Estou aprendendo mais a cada dia! No momento, só respondo nas páginas de consultas Superbox.",
        "Oops, minhas habilidades estão em desenvolvimento. Por enquanto, só posso responder em consultas Superbox.",
        "Ainda estou crescendo em conhecimento. Visite as páginas de consultas Superbox para obter respostas!",
        "Minha programação atual me permite responder apenas nas páginas de consultas Superbox. Nos vemos lá!",
      ],

      listaAvisosInicio: [
        `Bem-vindo {nome_user}, meu nome é Singú, como posso ajudar?`,
        `Singú na área! {cumprimento} {nome_user}, posso ajudar?`,
        `Olá {nome_user}! Como posso ajudar você hoje?`,
        `Olá, que bom ver você {nome_user}! Como posso ajudar?`,
        `Olá {nome_user}! Singú pronto para te ajudar. O que gostaria?`,
      ],

      listaAvisosInicioFormatada: [],
      textoCodigo: "",

      rolagemAutomaticaHabilitada: true,

      ativarHistorico: false,
    };
  },

  watch: {
    usuarioLogado: {
      handler(newVal) {
        if (newVal && newVal.NomeAmigavel) {
          this.adicionarMensagemBoasVindas();
        }
      },
      immediate: true,
      deep: true,
    },

    historicoChat: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.adicionarDivisores();
        }
      },
      immediate: true,
      deep: true,
    },

    tipoAssistenteSelecionado: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (newVal == "singuOutbox" && !oldVal) {
            this.historicoChatGeral = this.historicoChat;
          }
          if (oldVal == "singuOutbox") {
            this.historicoChatGeral = this.historicoChat;
          }
          if (oldVal == "singuConsorcio") {
            this.historicoChatConsorcio = this.historicoChat;
          }
          // if (oldVal == "singuSuperbox") {
          //   this.historicoChatSuperbox = this.historicoChat;
          // }
          if (newVal == "singuOutbox") {
            this.historicoChat = this.historicoChatGeral;
            if (this.historicoChat.length == 0) {
              this.adicionarMensagemBoasVindas();
            }
          }
          if (newVal == "singuConsorcio") {
            this.historicoChat = this.historicoChatConsorcio;
            if (this.historicoChat.length == 0) {
              this.adicionarMensagemBoasVindas();
              this.recuperarHistoricoChatConsorcioContagem();
            }
          }
          // if (newVal == "singuSuperbox") {
          //   this.historicoChat = this.historicoChatSuperbox;
          //   if (this.historicoChat.length == 0) {
          //     this.adicionarMensagemBoasVindas();
          //   }
          // }
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    novoChat() {
      if (this.historicoChat.length > 1) {
        this.historicoChat = [];
        this.thread_id = "";
        this.resetStoreChat();
        this.adicionarMensagemBoasVindas();
        this.$refs.saidaChatOutbox.novoChat();
      }
    },

    enviaHistoricoSelecionado(historico) {
      this.ativarHistorico = true;
      if (historico.flag_thread_ativa) {
        this.thread_id = historico.thread_id;
        this.historicoChat = [];

        this.$refs.saidaChatOutbox.resetaPagina();
        this.$refs.saidaChatOutbox.enviaCarregarMensagens(historico.thread_id);
      }
    },

    recuperaDiaSemana(data) {
      const [dia, mes, ano] = data.split("/");
      const date = new Date(ano, mes - 1, dia);
      return date.toLocaleString("default", { weekday: "long" });
    },

    ajustaTextoDivisor(data) {
      const [dia, mes, ano] = data.split("/");
      const dataFormatada = new Date(ano, mes - 1, dia);
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);

      const inicioSemana = new Date(hoje);
      inicioSemana.setDate(hoje.getDate() - hoje.getDay() + 1);

      const fimSemana = new Date(inicioSemana);
      fimSemana.setDate(fimSemana.getDate() + 6);

      var nomeDia = this.recuperaDiaSemana(data);
      return data === this.formatarData(hoje)
        ? data + " (" + "Hoje" + ")"
        : data + " (" + nomeDia.charAt(0).toUpperCase() + nomeDia.slice(1) + ")";
    },

    formatarData(data) {
      return data.toLocaleDateString("pt-BR");
    },

    adicionarDivisores() {
      const hoje = this.formatarData(new Date());
      for (let i = 0; i < this.historicoChat.length - 1; i++) {
        if (this.historicoChat[i].data !== this.historicoChat[i + 1].data) {
          const nomeDia = this.ajustaTextoDivisor(this.historicoChat[i + 1].data);
          if (!this.historicoChat[i + 1].divisor) {
            let divisor = {
              texto: nomeDia,
              divisor: true,
              data: this.historicoChat[i + 1].data,
              enviado: true,
            };
            this.historicoChat.splice(i + 1, 0, divisor);
            i++;
          }
        }
      }
    },

    atualizaHistorico(novosItensHistorico) {
      this.historicoChat = [...novosItensHistorico.reverse(), ...this.historicoChat];

      this.historicoAtivo = true;
    },

    checkTransicao(texto) {
      this.entradaChat = texto;
      clearTimeout(this.timerId);

      if (this.entradaChat.length > 0 && this.imagemAtual !== "logoDigitando") {
        this.imagemAtual = "logoRoboParaPonto";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoDigitando";
        }, 1000);
      } else if (this.entradaChat.length === 0 && this.imagemAtual !== "logoIAPadrao") {
        this.imagemAtual = "logoPontoParaRobo";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoIAPadrao";
        }, 1000);
      }

      this.carregandoResposta = false;
    },

    async prosseguirChat() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      await this.substituiPreferencia(nomePrefTermosDeUso, this.checkTermosUsoAssistente);
      this.termosUsoAssistente = this.checkTermosUsoAssistente;

      this.adicionarMensagemBoasVindas();
    },

    async adicionarMensagemBoasVindas() {
      if (
        this.historicoChat.length === 0 &&
        this.termosUsoAssistente &&
        this.usuarioLogado.NomeAmigavel != undefined
      ) {
        this.carregandoResposta = true;

        this.atualizaHora();

        const mensagemBoasVindas = this.getFraseAleatoriaInicio();

        await this.simularDigitacao(mensagemBoasVindas);
        this.checkTransicao("");
      }
    },

    async enviarMensagemSingu(texto) {
      await this.simularDigitacao(texto);
      this.checkTransicao("");
    },

    async enviarImagemSingu(texto, base64String) {
      if (texto !== "") {
        let respostaProcessada = this.processandoTexto(texto);
        await this.simularDigitacao(respostaProcessada);
        this.checkTransicao("");
      }

      this.criaMensagemImage();

      this.$nextTick(() => this.scrollParaBaixo());
    },

    async criaMensagemImage() {
      this.atualizaHora();

      if (this.caminhoImagemTemporario != null && this.caminhoImagemTemporario !== "") {
        this.historicoChat.push({
          data: this.dataFormatada,
          hora: this.horaFormatada,
          texto: "",
          enviado: false,
          contexto: this.contexto,
          tipo: "imagem",
          imagem: this.caminhoImagemTemporario,
        });
      }

      this.caminhoImagemTemporario = "";
    },

    getFraseAleatoriaInicio() {
      this.prepararListaAvisosInicio();

      const indiceAleatorio = Math.floor(
        Math.random() * this.listaAvisosInicioFormatada.length
      );

      return this.listaAvisosInicioFormatada[indiceAleatorio];
    },

    prepararListaAvisosInicio() {
      const nomeUser = this.usuarioLogado.NomeAmigavel;
      const cumprimento = this.getCumprimento();

      this.listaAvisosInicioFormatada = this.listaAvisosInicio.map((frase) =>
        frase
          .replace("{nome_user}", `${nomeUser}`)
          .replace("{cumprimento}", `${cumprimento}`)
      );
    },

    getCumprimento() {
      const horaAtual = new Date().getHours();
      if (horaAtual >= 5 && horaAtual < 12) return "Bom dia";
      if (horaAtual >= 12 && horaAtual < 18) return "Boa tarde";
      return "Boa noite";
    },

    atualizaHora() {
      const agora = new Date();
      this.dataFormatada = "";
      this.dataFormatada = agora.toLocaleDateString("pt-BR");
      this.horaFormatada = "";
      this.horaFormatada = agora.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    async enviarChat(texto) {
      this.entradaChat = texto;
      this.rolagemAutomaticaHabilitada = true;

      if (
        !this.carregandoResposta &&
        !this.carregandoRequisicao &&
        this.entradaChat != "" &&
        this.termosUsoAssistente
      ) {
        // this.stopSimulacao();
        this.carregandoResposta = true;
        const pergunta = this.entradaChat.replace(/\n/g, "<br>");
        this.atualizaHora();

        this.historicoChat.push({
          data: this.dataFormatada,
          hora: this.horaFormatada,
          texto: await this.processarMarkdown(pergunta),
          enviado: true,
          contexto: this.contexto,
          tipo: "texto",
        });

        this.entradaChat = "";

        this.$nextTick(() => {
          this.scrollParaBaixo();
        });

        if (this.tipoAssistenteSelecionado == "singuOutbox") {
          await this.obterResposta(pergunta);
        }

        if (this.tipoAssistenteSelecionado == "singuConsorcio") {
          await this.obterRespostaChatConsorcio(pergunta);
        }
      }
    },

    async obterResposta(pergunta) {
      const letras = pergunta.match(/[a-zA-Z]/g);
      if (letras && letras.length >= 2) {
        this.carregandoRequisicao = true;

        await this.requisicaoChatGeral(pergunta, this.thread_id);
        this.carregandoRequisicao = false;

        if (this.resultadoRequisiçãoGeral.resposta) {
          var respostaMarkdown = this.resultadoRequisiçãoGeral.resposta;
        } else if (typeof this.resultadoRequisiçãoGeral == "string") {
          var respostaMarkdown = this.resultadoRequisiçãoGeral;
        } else {
          var respostaMarkdown = "Assistente indisponível.";
        }

        if (this.resultadoRequisiçãoGeral.thread_id) {
          this.thread_id = this.resultadoRequisiçãoGeral.thread_id;
        }

        const respostaProcessada = this.processandoTexto(respostaMarkdown);
        await this.simularDigitacao(respostaProcessada);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
      } else {
        const textoPermissao = this.getFraseAleatoriaInvalido();
        await this.simularDigitacao(textoPermissao);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
      }
    },

    async obterRespostaSuperbox(pergunta) {
      const letras = pergunta.match(/[a-zA-Z]/g);
      if (letras && letras.length >= 2) {
        this.carregandoRequisicao = true;

        await this.requisicaoChatGeral(pergunta, this.thread_id);
        this.carregandoRequisicao = false;

        if (this.resultadoRequisiçãoGeral.resposta) {
          var respostaMarkdown = this.resultadoRequisiçãoGeral.resposta;
        } else if (typeof this.resultadoRequisiçãoGeral == "string") {
          var respostaMarkdown = this.resultadoRequisiçãoGeral;
        } else {
          var respostaMarkdown = "Assistente indisponível.";
        }

        if (this.resultadoRequisiçãoGeral.thread_id) {
          this.thread_id = this.resultadoRequisiçãoGeral.thread_id;
        }

        const respostaProcessada = this.processandoTexto(respostaMarkdown);
        await this.simularDigitacao(respostaProcessada);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
      } else {
        const textoPermissao = this.getFraseAleatoriaInvalido();
        await this.simularDigitacao(textoPermissao);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
      }
    },

    async obterRespostaChatConsorcio(pergunta) {
      if (!pergunta) {
        const textoPermissao = this.getFraseAleatoriaContexto();
        await this.simularDigitacao(textoPermissao);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
        return;
      }

      const letras = pergunta.match(/[a-zA-Z]/g);
      if (!letras || letras.length < 2) {
        const textoPermissao = this.getFraseAleatoriaInvalido();
        await this.simularDigitacao(textoPermissao);
        this.checkTransicao("");
        this.$nextTick(() => this.scrollParaBaixo());
        return;
      }

      this.carregandoRequisicao = true;
      await this.requisicaoChatConsorcio(pergunta);
      this.carregandoRequisicao = false;

      const { Imagem, Mensagem } = this.resultadoRequisiçãoConsorcio;
      const respostaMarkdown = Mensagem || "Assistente indisponível";

      if (!Imagem) {
        const respostaProcessada = this.processandoTexto(respostaMarkdown);
        await this.simularDigitacao(respostaProcessada);
        this.checkTransicao("");
      } else {
        this.caminhoImagemTemporario = Imagem;
        this.enviarImagemSingu(respostaMarkdown, Imagem);
      }

      this.$nextTick(() => this.scrollParaBaixo());
    },

    getFraseAleatoriaInvalido() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosInvalido.length);
      return this.listaAvisosInvalido[indiceAleatorio];
    },

    getFraseAleatoriaContexto() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosContexto.length);
      return this.listaAvisosContexto[indiceAleatorio];
    },

    processandoTexto(texto) {
      if (texto) {
        texto = this.limpezaTagsIncompletas(texto);
        texto = this.processarMarkdown(texto);
        texto = this.formataTextoCodigo(texto);
        texto = this.formataTextoTabela(texto);
        texto = this.formataTextoLink(texto);
        texto = this.formatarQuebrasDeLinha(texto);
      }

      return texto;
    },

    async simularDigitacao(texto) {
      this.carregandoResposta = true;
      this.checkTransicao(texto);
      this.atualizaHora();

      let i = 0;
      const velocidade = 10;

      this.historicoChat.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: "",
        enviado: false,
        contexto: this.contexto,
        simulação: true,
        tipo: "texto",
      });

      // texto = this.processandoTexto(texto);

      this.textoCompletoSimulado = texto;
      return new Promise((resolve) => {
        this.carregandoResposta = true;

        this.idIntervaloSimulacao = setInterval(() => {
          if (i < texto.length) {
            let textoParcial = texto.substring(0, i + 1);

            if (texto[i] === "<") {
              const closeTagIndex = texto.indexOf(">", i);
              if (closeTagIndex !== -1) {
                textoParcial = texto.substring(0, closeTagIndex + 1);
                i = closeTagIndex;
              }
            }

            this.scrollParaBaixo();

            this.atualizarChat(textoParcial, true);
            i++;
          } else {
            clearInterval(this.idIntervaloSimulacao);
            this.idIntervaloSimulacao = null;
            resolve();

            this.$nextTick(() => this.scrollParaBaixo());
            this.carregandoResposta = false;
          }
        }, velocidade);
      });
    },

    escapeHTML(str) {
      return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },

    async stopSimulacao() {
      if (this.idIntervaloSimulacao) {
        clearInterval(this.idIntervaloSimulacao);
        this.idIntervaloSimulacao = null;

        const ultimoTexto = this.historicoChat.pop();

        this.historicoChat.push({
          ...ultimoTexto,
          texto: this.textoCompletoSimulado,
          simulação: false,
          tipo: "texto",
        });

        this.carregandoResposta = false;
        this.$nextTick(() => this.scrollParaBaixo());
      }
      
      this.criaMensagemImage();
      this.checkTransicao("");
    },

    atualizarChat(texto, isSimulating = false) {
      if (isSimulating) {
        this.historicoChat.pop();
      }

      this.historicoChat.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: texto,
        enviado: false,
        contexto: this.contexto,
        tipo: "texto",
      });
    },

    aplicarFormato(texto, tipo) {
      if (tipo === "negrito") {
        return `<strong>${texto}</strong>`;
      } else if (tipo === "italico") {
        return `<em>${texto}</em>`;
      }
      return texto;
    },

    scrollParaBaixo() {
      let saidaChatOutbox = this.$refs.saidaChatOutbox;
      if (saidaChatOutbox && saidaChatOutbox.$refs.chatContainer) {
        let chatContainer = saidaChatOutbox.$refs.chatContainer;

        if (chatContainer && this.rolagemAutomaticaHabilitada) {
          chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    },

    handleInteracaoUsuario(estaNoFundo) {
      this.rolagemAutomaticaHabilitada = estaNoFundo;
    },

    async varificaPreferenciaTermos() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      const preferenciasFavoritos = await this.syncPreferenciaGeral(nomePrefTermosDeUso);
      if (preferenciasFavoritos === undefined) {
        await this.createPreferencia(nomePrefTermosDeUso);
        await this.substituiPreferencia(nomePrefTermosDeUso, false);
      } else {
        if (preferenciasFavoritos.Valor && preferenciasFavoritos.Valor != "") {
          this.termosUsoAssistente = preferenciasFavoritos.Valor == "true";
        }
      }
    },

    resetChat() {
      this.entradaChat = "";
      this.thread_id = "";
      this.historicoChat = [];
    },

    ...mapActions(chatOutboxStore, [
      "requisicaoChatGeral",
      "requisicaoChatConsorcio",
      "abrirJanelaChat",
      "fecharJanelaChat",
    ]),

    ...mapActions(chatOutboxHistoricoStore, [
      "resetStoreChat",
      "recuperarHistoricoChatGeralContagem",
      "recuperarHistoricoChatConsorcioContagem",
    ]),

    ...mapActions(chatOutboxFormatacoesStore, [
      "processarMarkdown",
      "formatarQuebrasDeLinha",
      "formataTextoCodigo",
      "formataTextoTabela",
      "formataTextoLink",
      "limpezaTagsIncompletas",
    ]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),
  },

  computed: {
    digitando() {
      if (this.entradaChat.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    ...mapWritableState(chatOutboxStore, {
      contexto: "contexto",
      resultadoRequisiçãoGeral: "resultadoRequisiçãoGeral",
      resultadoRequisiçãoConsorcio: "resultadoRequisiçãoConsorcio",
      historicoChatGeral: "historicoChatGeral",
      historicoChatSuperbox: "historicoChatSuperbox",
      historicoChatConsorcio: "historicoChatConsorcio",
    }),

    ...mapWritableState(chatOutboxFormatacoesStore, {}),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      termosUsoAssistente: "termosUsoAssistente",
    }),
  },

  async mounted() {
    this.resetChat();
    await this.varificaPreferenciaTermos();

    this.adicionarMensagemBoasVindas();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleCopyClick);
  },
};
</script>

<style lang="scss" scoped>
.container-box {
  display: flex;
  width: 100%;
  height: calc(100vh - 15vh);
  max-height: calc(100vh - 15vh);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0 20px;

  @include mediaQueryMax(medium) {
    max-height: fit-content;
    height: 100%;
  }
}

.box-selecao {
  display: flex;
  width: 100%;
}

.input-select {
  width: 270px;
}

.box-saida {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  height: 100%;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
}

.overlay {
  width: 101%;
  height: 101%;
  background-color: #45454587;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 0 5px;
  position: absolute;
  left: 0;
  top: 0;
}

.check-termo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .checkbox {
    margin: 5px 5px 0 0;
  }

  .texto-check {
    font-weight: 400;
  }
}

.botoes-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .botao-termos {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    height: 35px;
    width: 120px;

    &.prosseguir {
      background-color: #007bff;
      color: white;
    }

    &.declinar {
      background-color: white;
      color: black;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}
</style>

<style>
.code-block {
  padding: 10px;
  background-color: #000000;
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: block;
  white-space: pre-wrap;
  overflow-x: auto;
}

.code-header {
  font-family: Arial, sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #555555;
  color: #ffffff;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 35px;
}

.copy-btn {
  padding: 4px 8px;
  background-color: #e2e2e2;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.copy-btn:hover {
  background-color: #d6d6d6;
}
</style>
